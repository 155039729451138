<mat-form-field [appearance]="appearance" class="h-full" [ngClass]="{ 'no-hint-area': noMargin }" floatLabel="always">
    <mat-label>{{ 'common.date.range' | translate }}</mat-label>

    <ng-content select="[at-form-field]"></ng-content>
    <input matInput class="hidden" [formControl]="form" />
    <div [formGroup]="form" class="flex gap-1">
        <ng-container *ngTemplateOutlet="rangeStart"></ng-container>
        <span [ngClass]="{'text-gray-500': disabled}">~</span>
        <ng-container *ngTemplateOutlet="rangeEnd"></ng-container>
    </div>

    <div matSuffix style="width: 80px; display: flex;" *ngIf="show_prev_next_btn">
        <button mat-icon-button [disabled]="disabled" (click)="handlePrev();$event.stopPropagation();"><mat-icon>keyboard_arrow_left</mat-icon></button>
        <button mat-icon-button [disabled]="disabled" (click)="handleNext();$event.stopPropagation();"><mat-icon>keyboard_arrow_right</mat-icon></button>
    </div>

    <mat-error *ngIf="form.controls.start.hasError('matStartDateInvalid')">{{ 'msg.invalid_start_date' | translate }}</mat-error>
    <mat-error *ngIf="form.controls.end.hasError('matEndDateInvalid')">{{ 'msg.invalid_end_date' | translate }}</mat-error>
    <mat-error *ngIf="form.hasError('max-days')">{{ form.errors['max-days'].msg | translate: { days: form.errors['max-days'].days } }}</mat-error>
    <mat-error *ngIf="form.hasError('before-start')">{{ 'msg.invalid_end_date' | translate }}</mat-error>
</mat-form-field>

<ng-template #rangeStart>
    <input class="bg-inherit inline-flex w-20 disabled:text-gray-500" (click)="picker.open()" [matDatepicker]="picker" name="start" [formControl]="form.get('start')" placeholder="{{ 'common.date.start' | translate }}" readonly [disabled]="disabled" />
    <mat-datepicker #picker></mat-datepicker>
</ng-template>

<ng-template #rangeEnd>
    <input class="bg-inherit inline-flex w-20 disabled:text-gray-500" (click)="picker.open()" [matDatepicker]="picker" name="end" [formControl]="form.get('end')" placeholder="{{ 'common.date.end' | translate }}" readonly [disabled]="disabled" />
    <mat-datepicker #picker></mat-datepicker>
</ng-template>
