import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { IDType } from '../../modules/pv-ui-components/id-type-selector/models';
import { WebtagOption, SiteGroupOption, SiteOption, EquipmentOption, ApiResponse, CountryOption } from '../interfaces';
import { ApiService } from './api.service';
import { BackendHost, BackendPath } from 'app/configs/backend';
import { debounceTime, finalize, map, share, shareReplay, tap } from 'rxjs/operators';

const backend = `${BackendHost}${BackendPath}`;

@Injectable({ providedIn: 'root' })
export class SiteSelectorService {

    // IDType
    private _IDTypeSubject: BehaviorSubject<IDType> = new BehaviorSubject<IDType>(IDType.UserCode);
    $IDType: Observable<IDType> = this._IDTypeSubject.pipe( debounceTime(100) );
    IDTypeControl: FormControl = new FormControl<IDType>(IDType.UserCode);

    // Webtag
    $WebtagOptions: BehaviorSubject<WebtagOption[]> = new BehaviorSubject([]);
    private _WebtagSubject: BehaviorSubject<WebtagOption> = new BehaviorSubject<WebtagOption>(null);
    $Webtag: Observable<WebtagOption> = this._WebtagSubject.pipe( debounceTime(100) );
    WebtagControl: UntypedFormControl = new UntypedFormControl(null);

    // Site Group
    $SiteGroupOptions: BehaviorSubject<SiteGroupOption[]> = new BehaviorSubject([]);
    private _SiteGroupSubject: BehaviorSubject<SiteGroupOption> = new BehaviorSubject<SiteGroupOption>(null);
    $SiteGroup: Observable<SiteGroupOption> = this._SiteGroupSubject.pipe( debounceTime(100) );
    SiteGroupControl: UntypedFormControl = new UntypedFormControl(null);
    private _fetchSiteGroupSignal: Subject<void> = new Subject<void>();
    private _siteGroupOnFetch: boolean = false;
    private _siteGroupRequest: Subscription | null = null;

    // Site
    _SiteOptions: BehaviorSubject<SiteOption[]> = new BehaviorSubject([]);
    $SiteOptions: Observable<SiteOption[]> = this._SiteOptions.pipe(
        map( arr => arr.sort(
                (a,b) => {
                    switch( this.IDTypeControl.value )
                    {
                        case IDType.OwnerCode: return sortByString(a.owner_site, b.owner_site);
                        case IDType.UserCode: return sortByString(a.user_site, b.user_site);
                        case IDType.SystemCode: return sortByString(a.site, b.site);
                        default: return sortByString(a.user_site, b.user_site);
                    }
                }
            )
        )
    )
    private _SiteSubject: ReplaySubject<SiteOption> = new ReplaySubject<SiteOption>(1);
    $Site: Observable<SiteOption> = this._SiteSubject.asObservable();
    SiteControl: UntypedFormControl = new UntypedFormControl(null);
    SiteControlMultiple: UntypedFormControl = new UntypedFormControl([]);
    private _fetchSiteSignal: Subject<void> = new Subject<void>();
    private _siteOnFetch: boolean = false;
    private _siteRequest: Subscription | null = null;

    // Equipment
    _EquipmentOptions: BehaviorSubject<EquipmentOption[]> = new BehaviorSubject([]);
    $EquipmentOptions: Observable<EquipmentOption[]> = this._EquipmentOptions.pipe(
        map(arr => arr.sort(
            (a,b) => {
                switch( this.IDTypeControl.value )
                {
                    case IDType.OwnerCode: return sortByString(a.owner_eqp_id, b.owner_eqp_id);
                    case IDType.UserCode: return sortByString(a.user_eqp_id, b.user_eqp_id);
                    case IDType.SystemCode: return sortByString(a.eqp_id, b.eqp_id);
                    default: return sortByString(a.user_eqp_id, b.user_eqp_id);
                }
            }
        ))
    );
    private _EquipmentSubject: ReplaySubject<EquipmentOption> = new ReplaySubject<EquipmentOption>(1);
    $Equipment: Observable<EquipmentOption> = this._EquipmentSubject.asObservable();
    EquipmentControl: UntypedFormControl = new UntypedFormControl(null);
    EquipmentControlMultiple: UntypedFormControl = new UntypedFormControl([]);
    private _fetchEquipmentSignal: Subject<void> = new Subject<void>();
    private _equipmentRequest: Subscription | null = null;

    // Sunshine Equipment
    $SunshineEquipmentOptions: BehaviorSubject<EquipmentOption[]> = new BehaviorSubject([]);
    private _SunshineEquipmentSubject: ReplaySubject<EquipmentOption> = new ReplaySubject<EquipmentOption>(1);
    $SunshineEquipment: Observable<EquipmentOption> = this._SunshineEquipmentSubject.asObservable();
    SunshineEquipmentControl: UntypedFormControl = new UntypedFormControl(null);
    private _fetchSunshineEquipmentSignal: Subject<void> = new Subject<void>();
    private _sunshineEquipmentRequest: Subscription | null = null;

    // Anemometer Equipment
    $AnemometerEquipmentOptions: BehaviorSubject<EquipmentOption[]> = new BehaviorSubject([]);
    private _AnemometerEquipmentSubject: ReplaySubject<EquipmentOption> = new ReplaySubject<EquipmentOption>(1);
    $AnemometerEquipment: Observable<EquipmentOption> = this._AnemometerEquipmentSubject.asObservable();
    AnemometerEquipmentControl: UntypedFormControl = new UntypedFormControl(null);
    private _fetchAnemometerEquipmentSignal: Subject<void> = new Subject<void>();
    private _anemometerEquipmentRequest: Subscription | null = null;

    _AllSiteOptions: BehaviorSubject<SiteOption[]> = new BehaviorSubject<SiteOption[]>([]);
    $AllSiteOptions: Observable<SiteOption[]> = this._AllSiteOptions.asObservable();
    private _allSiteQuery: Subscription | null = null;

    private _AllInverterOptions: BehaviorSubject<EquipmentOption[]> = new BehaviorSubject<EquipmentOption[]>([]);
    $AllInverterOptions: Observable<EquipmentOption[]> = this._AllInverterOptions.asObservable();

    private _AllEquipmentOptionsState: BehaviorSubject<'ready' | 'loading' | 'pending' | 'error'> = new BehaviorSubject('pending');
    $AllEquipmentOptionsState: Observable<'ready' | 'loading' | 'pending' | 'error'> = this._AllEquipmentOptionsState.asObservable();
    private _AllEquipmentOptions: BehaviorSubject<EquipmentOption[]> = new BehaviorSubject<EquipmentOption[]>([]);
    $AllEquipmentOptions: Observable<EquipmentOption[]> = this._AllEquipmentOptions.pipe(tap(() => {
        if (this._AllEquipmentOptionsState.getValue() == 'pending' || this._AllEquipmentOptionsState.getValue() == 'error'){
            this._AllEquipmentOptionsState.next('loading');
            this.fetchAllEquipmentOptions();
        }
    }));
    private _allEquipmentQuery: Subscription | null = null;

    constructor(
        public api: ApiService,
        public authService: AuthService,
    ) {
        /** Control Change */
        // IDType
        this.IDTypeControl.valueChanges.subscribe( v => {
            this._IDTypeSubject.next( v );
            // Trigger to sort options
            this._SiteOptions.next( this._SiteOptions.value );
            this._EquipmentOptions.next( this._EquipmentOptions.value );
        });
        // Webtag
        this.WebtagControl.valueChanges.subscribe((v: WebtagOption) => {
            this._fetchSiteGroupSignal.next();
            this.fetchAllSiteOptions();
        });
        // Site Group
        this.SiteGroupControl.valueChanges.subscribe((v: SiteGroupOption) => {
            this._fetchSiteSignal.next();
            this.SiteControlMultiple.patchValue([]);
        });
        // Site
        this.SiteControl.valueChanges.subscribe((v: SiteOption) => {
            this.EquipmentControl.patchValue(null);
            this.AnemometerEquipmentControl.patchValue(null);
            this.SunshineEquipmentControl.patchValue(null);
            this.EquipmentControlMultiple.patchValue([]);
            this._fetchEquipmentSignal.next();
            this._fetchSunshineEquipmentSignal.next();
            this._fetchAnemometerEquipmentSignal.next();
        });
        // Equipment
        // this.EquipmentControl.valueChanges.subscribe((v: EquipmentOption) => {  });

        /** Options Change  */
        // Webtag
        this.$WebtagOptions.subscribe(opts => {
            if( !this.WebtagControl.value ) {
                const value = opts.find( opt => opt.webtag_id == this.authService.getWebtag().domain ) || opts[0];
                this.WebtagControl.patchValue( value );
            } else {
                const webtag_id = this.WebtagControl.value.webtag_id;
                const value = opts.find( opt => opt.webtag_id == webtag_id ) || opts.find( opt => opt.webtag_id == this.authService.getWebtag().domain );
                this.WebtagControl.patchValue( value );
            }
        });
        // Site Group
        this.$SiteGroupOptions.subscribe(opts => {
            if( !this.SiteGroupControl.value || !opts.find( opt => opt.group_id == this.SiteGroupControl.value.group_id ) ) {
                const user = this.authService.getUser();
                const users_group = opts.findIndex(grp => grp.group_id == user.site_group_id || grp.id == user.site_group_sn);
                if( users_group >= 0 ) {
                    this.SiteGroupControl.patchValue( opts[users_group] );
                } else {
                    this.SiteGroupControl.patchValue( opts[0] || null );
                }
            } else {
                const idx = opts.findIndex( opt => opt.group_id == this.SiteGroupControl.value.group_id );
                this.SiteGroupControl.patchValue( this.$SiteGroupOptions.value[idx] || null );
            }
        });
        // Site
        this.$SiteOptions.subscribe(opts => {
            if( !this.SiteControl.value || !opts.find( opt => opt.site == this.SiteControl.value?.site ) ) {
                this.SiteControl.patchValue( this._SiteOptions.value[0] );
            } else {
                const idx = opts.findIndex( opt => opt.site == this.SiteControl.value?.site );
                this.SiteControl.patchValue( this._SiteOptions.value[idx] );
            }
        });
        // Equipment
        this.$EquipmentOptions.subscribe(opts => {
            if( !this.EquipmentControl.value || !opts.find( opt => opt.eqp_id == this.EquipmentControl.value?.eqp_id ) ) {
                this.EquipmentControl.patchValue( this._EquipmentOptions.value[0] );
            } else {
                const idx = opts.findIndex( opt => opt.eqp_id == this.EquipmentControl.value?.eqp_id );
                this.EquipmentControl.patchValue( this._EquipmentOptions.value[idx] );
            }
        });
        this.$SunshineEquipmentOptions.subscribe(opts => {
            if( !this.SunshineEquipmentControl.value || !opts.find( opt => opt.eqp_id == this.SunshineEquipmentControl.value?.eqp_id ) ) {
                this.SunshineEquipmentControl.patchValue( this.$SunshineEquipmentOptions.value[0] );
            } else {
                const idx = opts.findIndex( opt => opt.eqp_id == this.EquipmentControl.value?.eqp_id );
                this.SunshineEquipmentControl.patchValue( this.$SunshineEquipmentOptions.value[idx] );
            }
        });
        this.$AnemometerEquipmentOptions.subscribe(opts => {
            if( !this.AnemometerEquipmentControl.value || !opts.find( opt => opt.eqp_id == this.AnemometerEquipmentControl.value?.eqp_id ) ) {
                this.AnemometerEquipmentControl.patchValue( this.$AnemometerEquipmentOptions.value[0] );
            } else {
                const idx = opts.findIndex( opt => opt.eqp_id == this.EquipmentControl.value?.eqp_id );
                this.AnemometerEquipmentControl.patchValue( this.$AnemometerEquipmentOptions.value[idx] );
            }
        });

        // Fetch Signals
        this._fetchSiteGroupSignal.pipe( debounceTime(100) ).subscribe(() => this.fetchSiteGroupOptions());
        this._fetchSiteSignal.pipe( debounceTime(100) ).subscribe(() => this.fetchSiteOptions());
        this._fetchEquipmentSignal.pipe( debounceTime(100) ).subscribe(() => this.fetchEquipmentOptions());
        this._fetchSunshineEquipmentSignal.pipe( debounceTime(100) ).subscribe(() => this.fetchSunshineEquipmentOptions());
        this._fetchAnemometerEquipmentSignal.pipe( debounceTime(100) ).subscribe(() => this.fetchAnemometerEquipmentOptions());

        // Init
        this.fetchWebtagOptions();
        this.authService.loginUser.subscribe((user) => {
            if( user ) {
                this.fetchWebtagOptions();
                // this.fetchAllEquipmentOptions();
            } else {
                this._EquipmentOptions.next([]);
                this._SiteOptions.next([]);
                this.$SunshineEquipmentOptions.next([]);
                this.$AnemometerEquipmentOptions.next([]);
                this.$SiteGroupOptions.next([]);
                this.$WebtagOptions.next([]);
                this._AllEquipmentOptions.next([]);
                this._AllInverterOptions.next([]);
            }
        });
    }

    fetchWebtagOptions() {
        const req: Observable<ApiResponse<WebtagOption[]>> = this.api.get(`${backend}/auth/user/utils/webtag_options`)
        req.subscribe(res => {
            this.$WebtagOptions.next(res.data);
        }, error => {
            const webtag = this.authService.getWebtag();
            this.$WebtagOptions.next([
                {
                    webtag_id: webtag.domain,
                    name: webtag.name,
                }
            ] as WebtagOption[])
        });
    }

    fetchSiteGroupOptions() {
        if (this._siteGroupRequest) {
            this._siteGroupRequest.unsubscribe();
        }
        this._siteGroupOnFetch = true;
        let webtag = this.WebtagControl.value;
        if( !webtag ) {
            webtag = this.$WebtagOptions.value[0];
        }

        const req: Observable<ApiResponse<SiteGroupOption[]>> = this.api.get(`${backend}/auth/user/utils/site_group_options`, {webtag_id: webtag?.webtag_id || ''})
        this._siteGroupRequest = req
            .pipe( finalize(() => {
                this._siteGroupOnFetch = false;
                this._siteGroupRequest = null;
            }) )
            .subscribe(res => {
                this.$SiteGroupOptions.next(res.data.sort((a,b) => sortByString(a.group_id, b.group_id)));
            });
    }

    fetchSiteOptions() {
        if (this._siteRequest) {
            this._siteRequest.unsubscribe();
        }
        if (this._siteGroupOnFetch) {
            this._fetchSiteSignal.next();
            return;
        }
        this._siteOnFetch = true;
        let webtag = this.WebtagControl.value;
        if( !webtag ) {
            webtag = this.$WebtagOptions.value[0];
        }
        let site_group = this.SiteGroupControl.value;

        const req: Observable<ApiResponse<SiteOption[]>> = this.api.get(`${backend}/auth/user/utils/site_options`, {webtag_id: webtag?.webtag_id || '', group_id: site_group?.group_id || ''})
        this._siteRequest = req
            .pipe(finalize(() => {
                this._siteOnFetch = false;
                this._siteRequest = null;
            }))
            .subscribe(res => {
                this._SiteOptions.next(res.data);
            });
    }

    getReadableSiteOptions(conditions: any = {}): Observable<ApiResponse<SiteOption[]>> {
        return this.api.get(`${backend}/auth/user/utils/site_options`, conditions);
    }

    fetchEquipmentOptions() {
        if (this._equipmentRequest) {
            this._equipmentRequest.unsubscribe();
        }
        if (this._siteOnFetch) {
            this._fetchEquipmentSignal.next();
            return;
        }
        const site = this.SiteControl.value;

        if( !site ) return;

        const req: Observable<ApiResponse<EquipmentOption[]>> = this.api.get(`${backend}/auth/user/utils/equipment_options`, { site_id: site.site })
        this._equipmentRequest = req
            .pipe( finalize(() => this._equipmentRequest = null) )
            .subscribe(res => {
                this._EquipmentOptions.next(res.data);
            });
    }

    fetchSunshineEquipmentOptions() {
        if (this._sunshineEquipmentRequest) {
            this._sunshineEquipmentRequest.unsubscribe();
        }
        if (this._siteOnFetch) {
            this._fetchSunshineEquipmentSignal.next();
            return;
        }
        const site = this.SiteControl.value;

        if( !site ) return;

        const req: Observable<ApiResponse<EquipmentOption[]>> = this.api.get(`${backend}/auth/user/utils/equipment_options`, { site_id: site.site, eqp_type: 'Sunshine' })
        this._sunshineEquipmentRequest = req
            .pipe( finalize(() => this._sunshineEquipmentRequest = null) )
            .subscribe(res => {
                this.$SunshineEquipmentOptions.next(res.data);
            });
    }

    fetchAnemometerEquipmentOptions() {
        if (this._anemometerEquipmentRequest) {
            this._anemometerEquipmentRequest.unsubscribe();
        }
        if (this._siteOnFetch) {
            this._fetchAnemometerEquipmentSignal.next();
            return;
        }
        const site = this.SiteControl.value;

        if( !site ) return;

        const req: Observable<ApiResponse<EquipmentOption[]>> = this.api.get(`${backend}/auth/user/utils/equipment_options`, { site_id: site.site, eqp_type: 'Anemometer' })
        this._anemometerEquipmentRequest = req
            .pipe( finalize(() => this._anemometerEquipmentRequest = null) )
            .subscribe(res => {
                this.$AnemometerEquipmentOptions.next(res.data);
            });
    }

    getReadableEquipmentOptions(conditions: any = {}): Observable<ApiResponse<EquipmentOption[]>> {
        return this.api.get(`${backend}/auth/user/utils/equipment_options`, conditions);
    }

    fetchAllEquipmentOptions()
    {
        if (this._allEquipmentQuery) {
            this._allEquipmentQuery.unsubscribe();
        }
        const req: Observable<ApiResponse<EquipmentOption[]>> = this.api.get(`${backend}/auth/user/utils/equipment_options`);
        this._allEquipmentQuery = req
            .pipe( finalize(() => this._allEquipmentQuery = null) )
            .subscribe(res => {
                this._AllInverterOptions.next(res.data.filter( opt => opt.eqp_type?.toLowerCase() == 'inverter' ));
                this._AllEquipmentOptions.next(res.data);
                this._AllEquipmentOptionsState.next('ready');
            }, error => {
                this._AllEquipmentOptionsState.next('error');
            });
    }

    getAllEquipments(): EquipmentOption[] {
        return this._AllEquipmentOptions.getValue();
    }

    fetchAllSiteOptions()
    {
        if (this._allSiteQuery) {
            this._allSiteQuery.unsubscribe();
        }
        const req: Observable<ApiResponse<SiteOption[]>> = this.api.get(`${backend}/auth/user/utils/site_options`);
        this._allSiteQuery = req
            .pipe( finalize(() => this._allSiteQuery = null) )
            .subscribe(res => {
                this._AllSiteOptions.next(res.data);
            });
    }

    _locationOptions$: Observable<ApiResponse<CountryOption[]>> = this.api.get(`${backend}/auth/user/utils/location_options`).pipe( share() );
    getLocationOptions() {
        return this._locationOptions$;
    }

}

const sortByString = (a: string, b: string): 0 | 1 | -1 => {
    if( a > b ) return 1;
    if( b > a ) return -1;

    return 0;
}
const sortByStringDesc = (a: string, b: string): 0 | 1 | -1 => {
    if( a < b ) return 1;
    if( b < a ) return -1;

    return 0;
}
