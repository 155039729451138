import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

/* Directives */
import { DataTableCellDef, DataTableColumnDef, DataTableHeaderCellDef, DataTablePanelToggleDef, DataTablePanelContentDef } from './directives/cell.directive';
import { DtAutoGridColumn, DtAutoGridWrapper } from './directives/auto-grid.directive';

/* Components */
import { DataTableComponent } from './data-table/data-table.component';
import { DataTableWrapperComponent } from './data-table-wrapper/data-table-wrapper.component';
import { DataTablePaginatorComponent } from './data-table-paginator/data-table-paginator.component';
import { DataTableColumnListComponent } from './data-table-column-list/data-table-column-list.component';

/* Material Modules */
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatBadgeModule } from '@angular/material/badge';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { DataTableDefaultOptions } from './models';
import { InjectionToken } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SiteSelectorModule } from './common/site-selector/site-selector.module';
import { IDTypeSelectorModule } from './common/id-type-selector/id-type-selector.module';
import { DtSortButtonComponent } from './common/dt-sort-button/dt-sort-button.component';
import { DateRangeModule } from '../../modules/form-ui-components/date-range.component';

const modules = [MatRippleModule, MatIconModule, MatButtonModule, MatDividerModule, MatSelectModule, MatInputModule, MatBadgeModule, MatMenuModule, MatListModule, MatCheckboxModule, DragDropModule, TranslateModule, MatTooltipModule, SiteSelectorModule, IDTypeSelectorModule, DateRangeModule];
const components = [DataTableWrapperComponent, DataTablePaginatorComponent, DataTableComponent, DataTableColumnListComponent, DtSortButtonComponent];
const directivies = [DataTableCellDef, DataTableHeaderCellDef, DataTablePanelToggleDef, DataTableColumnDef, DataTablePanelContentDef, DtAutoGridWrapper, DtAutoGridColumn];

export const DATATABLE_DEFAULT_OPTIONS = new InjectionToken<DataTableDefaultOptions>('DATATABLE_DEFAULT_OPTIONS', {
    providedIn: 'root',
    factory: () => ({
        paginator: {
            Limit: 10,
            LimitOptions: [10, 20, 40, 80, 120],
        },

        table:{ Selectable: false },

        wrapper: {
            FilterMinWidth: 150,
            EventDebounceTime: 100,
            ShowHeader: true,
            ShowColumnsConfigButton: true,
            ShowFilters: true,
        },
    })
});

@NgModule({
    declarations: [...components, ...directivies],
    imports: [CommonModule, ReactiveFormsModule, RouterModule, ...modules],
    exports: [...components, ...directivies]
})
export class DataTableModule { }
